import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"

import styles from "./styles.module.scss"

const Submit = () => (
  <Layout className={ styles.submit } id="submission-guidelines">
    <article>
      <SEO title="Submission Guidelines" />
      <h1>Submission Guidelines</h1>

      <p className="h6"><em>Lost and Bound</em> is a themed quarterly digital lifestyle zine of speculative identities, otherworldly non-fictions, and impossible realities. Each issue focuses on an undeserved fantastical community, telling their stories, exploring their needs, and presenting their beauty through fiction, non-fiction, erotica, games, crafts, recipes, fashion tips, and more. We aim to publish our first issue in the summer of 2021.</p>

      <ol className="small">
        <li><a href="#theme">Theme</a></li>
        <li><a href="#how-to-submit-your-work">How to Submit Your Work</a></li>
        <li><a href="#general-guidelines">General Guidelines</a></li>
        <li><a href="#whose-work-we-want-to-publish">Whose Work We Want to Publish</a></li>
        <li><a href="#sensitive-and-mature-content">Sensitive and Mature Content</a></li>
        <li><a href="#types-of-work-we-accept">Types of Work We Accept</a></li>
        <li><a href="#submission-format">Submission Format</a></li>
        <li><a href="#submission-frequency">Submission Frequency</a></li>
        <li><a href="#compensation">Compensation</a></li>
        <li><a href="#licensing-and-rights">Licensing and Rights</a></li>
        <li><a href="#response-time">Response Time</a></li>
      </ol>

      <h2 className="anchor" id="theme">Theme</h2>

      <p>Our first(!) issue celebrates <strong>symbionts and their hosts</strong>. <em>Remember, we only consider work that fits our current theme.</em></p>

      <h2 className="anchor" id="how-to-submit-your-work">How to Submit Your Work</h2>

      <p>Please use our <a href="http://bit.ly/lostandboundsubmissionsform"  target="_blank">submissions form.</a></p>

      <p>If you have questions, please email us at <a href="mailto:hello@getlostandbound.com">hello@getlostandbound.com</a>. If the form does not work for any reason, please email us your submission.</p>

      <h2 className="anchor" id="general-guidelines">General Guidelines</h2>

      <p>Think of <em>Lost and Bound</em> as a speculative fiction zine wrapped up in the trope of a lifestyle magazine. We love submissions that play with the lifestyle magazine trope in creative and unexpected ways. Each issue of <em>Lost and Bound</em> is themed around a speculative community, but in its guise as a lifestyle magazine, you can consider that <em>Lost and Bound</em> is created with members of that speculative community as its intended audience. Be sure any work you submit is centered on that community. How you explore these conceits is up to you. Be as literal or as loose as you prefer—go where your imagination takes you… and above all, have fun! Surprise us!</p>

      <p>We aim to use this publication to delve into questions of identity, exploration and self-exploration, discovery and self-discovery, transformation and self-transformation. We believe that by losing ourselves in stories of the impossible, the strange, the uncomfortable, and the fantastical—by embracing and embodying the other, we gain a new understanding of ourselves. We would love to receive submissions that embody that philosophy.</p>

      <h2 className="anchor" id="whose-work-we-want-to-publish">Whose Work We Want to Publish</h2>

      <p>Anyone with enthusiasm for speculative lifestyles!</p>

      <p>As a publication representing fantastical underrepresented communities, we are very excited about giving a voice to members of real world underrepresented groups, e.g. people of color, members of the LGBTQ+ community, women and femme identifying folks, disabled individuals, and any other group whose voice is rarely heard in mainstream media.</p>

      <h2 className="anchor" id="sensitive-and-mature-content">Sensitive and Mature Content</h2>

      <p>We are body positive—we love all bodies: human, animal, plant or robot; solid, gelatinous, liquid or gas... and we would like to see them all represented in our publication.</p>

      <p>We are sex and kink positive. We accept erotic content, but we prefer thoughtful, respectful work that isn’t solely designed to titillate.</p>

      <p>We accept other types of mature content (violence, strong language, ideologically sensitive content), but we will only consider work that includes these element with reason, not simply for shock value.</p>

      <p>We do <strong>not</strong> accept work that supports racism, sexism, or any other kind of discrimination or harmful stereotypes. <strong>Hate rhetoric is strongly condemned</strong> by <em>Lost and Bound.</em></p>

      <h2 className="anchor" id="types-of-work-we-accept">Types of Work We Accept</h2>

      <h3>Fiction</h3>

      <p><em>Prose under 7500 words.</em></p>

      <h3>Lifestyle Magazine Content</h3>

      <p><em>Written works under 7500 words.</em></p>

      <p>We will consider any classic lifestyle magazine content as long as it fits our theme, including, but not limited to:</p>

      <ul>
        <li>Food, drink, and diet</li>
        <li>Fashion, style, and beauty</li>
        <li>Health and wellness</li>
        <li>Love and relationships</li>
        <li>Travel</li>
        <li>Home decorating and entertaining</li>
        <li>Shopping</li>
        <li>Reviews</li>
      </ul>

      <h3>Non-Fiction</h3>

      <p><em>Articles under 7500 words</em></p>

      <p>We are particularly interested in articles that relate our theme to topics in science, history, mythology, psychology, or sociology.</p>

      <h3>Poetry</h3>

      <p><em>Poems under 50 lines.</em></p>

      <h3>Art</h3>

      <p><em>High resolution visual art, minimum 300 DPI.</em></p>

      <h3>Cover Art</h3>

      <p><em>Details TBD.</em></p>

      <p>More details on requirements and constraints once we have figured out the format of our first issue.</p>

      <h3>Short comics</h3>

      <p><em>Works of one page or less featuring images and words.</em></p>

      <h3>Graphic Stories</h3>

      <p><em>Multipage works under 10 pages featuring images and words.</em></p>

      <p>More details on requirements and constraints once we have figured out the format of our first issue.</p>

      <h3>Games and Puzzles</h3>

      <p><em>Written works under 7500 words. Visual works under 3 pages.</em></p>

      <p>Must be lo-fi enough to be publishable in a zine, and fit our theme. Examples: choose your own adventure stories, collaborative storytelling games, one shot RPGs, art games, mazes, word games…</p>

      <h2 className="anchor" id="types-of-work-not-to-submit">Types of Work Not to Submit</h2>

      <p>We only accept original work; no fan-fiction. Reprints are not accepted at this time.</p>

      <p>We do not accept simultaneous submissions at this time. We wish we could, but as a small volunteer-run organisation, we do not currently have the bandwidth to do so.</p>

      <h2 className="anchor" id="submission-format">Submission Format</h2>

      <p>You may submit up to 5 pieces for consideration.</p>

      <p>Images should be .png or .jpg files; writing should be .rtf, .doc or .docx files; multipage works that mix images and text should be .pdf files.</p>

      <p>Please include <strong>Content Warnings</strong> for any work that includes sensitive or mature content.</p>

      <h2 className="anchor" id="submission-frequency">Submission Frequency</h2>

      <p>Please limit multiple submissions to one pending submission at any time.</p>

      <p>Please do not submit work more than twice per themed issue.</p>

      <p>If a piece is rejected, please refrain from sending it to us a second time unless substantive changes have been made.</p>

      <h2 className="anchor" id="compensation">Compensation</h2>

      <p><em>Lost and Bound</em> is entirely volunteer run and self-funded, excluding what we bring in via our Patreon and through sales of our zine. We know our current rates are low, but we are actively working to offer more compensation as <em>Lost and Bound</em> grows. We believe writers, artists and creatives deserve to be paid, and we are committed to doing so at the highest rates we can afford.</p>

      <p>All rates are in USD and paid via Venmo (preferred) or PayPal.</p>

      <table>
        <tbody>
          <tr>
            <th>Type of work</th>
            <th>Compensation (USD)</th>
          </tr>
          <tr>
            <td>Short written works under 1000 words, poetry, short comics, spot illustrations</td>
            <td>$5 flat fee</td>
          </tr>
          <tr>
            <td>All other written works and visual art (excluding graphic stories)</td>
            <td>$10 flat fee</td>
          </tr>
          <tr>
            <td>Graphic stories</td>
            <td>$20 flat fee</td>
          </tr>
          <tr>
            <td>Cover art</td>
            <td>$50 flat fee</td>
          </tr>
        </tbody>
      </table>

      <h2 className="anchor" id="licensing-and-rights">Licensing and Rights</h2>

      <p>All work accepted will be bound by the terms of our <a href="http://bit.ly/lostandboundlicensingagreement" target="_blank">licensing agreement</a>.</p>

      <h2 className="anchor" id="response-time">Response Time</h2>

      <p>We are a small team of volunteers. We try to respond to all comments, queries, and concerns within a few days. Our submission response time is typically between 2-4 weeks, but please do not query regarding the status of a submission until 6 weeks have passed. We will respond to all submissions, whether they have been accepted or not, but we cannot offer critique with our responses.</p>

      <p className="h4"><em>Thanks for your submission!</em></p>
    </article>
  </Layout>
)

export default Submit
